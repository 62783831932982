<template>
  <el-tooltip :disabled="Number(balance) > 0" effect="dark" popper-class="notEnoughTooltipContainer">
    <template #content>
      <div>
        {{ $t('notEnoughToExchange') }}
      </div>
    </template>

    <div
      class="BurnCardcontainer"
      :class="{ 'burn-card-container-ru': $i18n.locale === 'ru' }"
      :style="{
        order: -BigNumber(Math.floor(Number(balance)))
          .multipliedBy(rate)
          .integerValue(BigNumber.ROUND_FLOOR)
          .toNumber()
      }"
      @click="burnCardClickHandler"
    >
      <el-dialog
        v-if="showExchangePopup"
        v-model="showExchangePopup"
        :title="
          $t('appStatisticExchangePopupTitle', {
            label: capitalize($t(token.label, { count: 3 }).toLowerCase()),
            pairLabel: capitalize($t(tokensConfig.seasonPoints.label, { count: 3 }).toLowerCase())
          })
        "
        modal-class="modal"
        :append-to-body="true"
        :fullscreen="$device.isMobile"
        class="exchangeModal"
      >
        <ExchangeModal
          :token-balance="BigNumber(balance).toString()"
          :rate="BigNumber(rate).toNumber()"
          :token-address="props.seasonToken.tokenAddress"
          :token-pair="tokensConfig.seasonPoints.name.contract"
          :exchange="exchange"
          @close-modal="showExchangePopup = false"
        />
      </el-dialog>
      <div class="imgContainer">
        <img width="82" :src="tokenId ? tokenURIImage : tokens[token?.name?.contract as Tokens]" alt="" />
      </div>
      <div
        v-if="
          tokenMetaData(blockchain.contracts, seasonToken.tokenAddress).interface === 'erc20' ||
          !BigNumber(balance).isZero()
        "
        class="rateBlock"
      >
        <p>{{ $t('appStatisticRate') }}:</p>
        <p class="rate">
          1 =
          {{ BigNumber(rate).integerValue(BigNumber.ROUND_FLOOR).toNumber() }}
          <img width="24" :src="tokens.seasonPoints" alt="" />
        </p>
      </div>

      <p class="balance">{{ availableTokensFormatted }}</p>
      <SeasonBurnCardLabel :max-amount-to-exchange="maxAmountTokensFormatted" />
    </div>
  </el-tooltip>
</template>
<script lang="ts" setup>
import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';
import { AbiCoder, BrowserProvider, getBytes, keccak256, Signature, ZeroAddress, zeroPadValue } from 'ethers';
import tokens from '~/public/img/tokens';
import type { TSeasonConfigItem } from '~/types/season';
import { Tokens, tokensConfig } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';
import { useI18n } from '#imports';
import { capitalize } from 'element-plus/es/utils/strings.mjs';

const { blockchain } = useEnvs();
const { availableTokens } = useTokensReader();
const { address, chainId } = useWeb3ModalAccount();
const { open } = useWeb3Modal();
const { getContractReadOnly } = useAbiAccess();

const { getContract } = useAbiAccess();
const { walletProvider } = useWeb3ModalProvider();
const { sendContractMethod } = useSendContractMethod();
const store = useMainStore();
const { t } = useI18n();

const confirmationNumberRef = ref(0);
const showExchangePopup = ref(false);
const nftValue = ref<number>(0);
const props = defineProps<{ seasonToken: TSeasonConfigItem; seasonId: number; tokenId?: number }>();
const token = computed(() => tokenMetaData(blockchain.contracts, props.seasonToken.tokenAddress));
const balance = asyncComputed(async () => {
  if (props.tokenId) return '1';
  if (!BigNumber(store.notificationUpdateVersion).isNaN())
    return availableTokens(address.value || ZeroAddress, props.seasonToken.tokenAddress);
  return '0';
}, '0');
const rate = computed(() =>
  props.tokenId
    ? BigNumber(props.seasonToken.pointsMultiplier).multipliedBy(nftValue.value).toString()
    : props.seasonToken.pointsMultiplier
);

const tokenURIImage = asyncComputed(async () => {
  const tokenContract = await getContractReadOnly('earrings', props.seasonToken.tokenAddress);

  const tokenUri = await tokenContract.tokenURI(props.tokenId);

  const image = await $fetch(tokenUri);
  console.log('image', image.image_url);

  return image.image_url;
});

watch(
  () => props.tokenId,
  async (newTokenId) => {
    if (newTokenId) {
      const tokenContract = await getContract('earrings', props.seasonToken.tokenAddress);
      nftValue.value = await tokenContract?.getTokenValue(newTokenId);
    }
  },
  { immediate: true }
);

const formatAmount = (amount: number | string) => {
  const absNum = BigNumber(amount).absoluteValue().toNumber();

  if (absNum >= 1e6) {
    return (absNum / 1e6).toFixed(1).replace(/\.?0+$/, '') + 'M';
  } else if (absNum >= 1e3) {
    return (absNum / 1e3).toFixed(1).replace(/\.?0+$/, '') + 'k';
  } else {
    return BigNumber(absNum).decimalPlaces(2);
  }
};

const availableTokensFormatted = computed(() => formatAmount(Math.floor(Number(balance.value))));
const maxAmountTokensFormatted = computed(() =>
  formatAmount(
    BigNumber(Math.floor(Number(balance.value)))
      .multipliedBy(rate.value)
      .integerValue(BigNumber.ROUND_FLOOR)
      .toNumber()
  )
);

const burnCardClickHandler = () => {
  if (!address.value || !walletProvider.value) {
    open();
    return;
  }

  if (Number(balance.value) === 0) return;

  showExchangePopup.value = true;
};

const exchange = async (quantity: number) => {
  if (!address.value || !walletProvider.value) {
    return;
  }
  const provider = new BrowserProvider(walletProvider.value);
  const signer = await provider.getSigner();

  const seasonContract = await getContract('season', blockchain.contracts.season);
  const nonce = await seasonContract?.getNonce(address.value);

  const digest = keccak256(
    AbiCoder.defaultAbiCoder().encode(
      ['address', 'uint256', 'uint256'],
      [blockchain.contracts.season, nonce, chainId.value]
    )
  );

  const signature = await signer.signMessage(getBytes(digest));

  const { r, s, v } = Signature.from(signature);

  const tx = await sendContractMethod(
    {
      contract: 'season',
      address: blockchain.contracts.season,
      methodName: 'useSeason',
      methodArguments: [
        [
          zeroPadValue(
            `0x${
              props.tokenId
                ? BigNumber(props.tokenId).toNumber().toString(16).padStart(24, '0')
                : quantity.toString(16).padStart(24, '0')
            }${props.seasonToken.tokenAddress?.slice(2)}`,
            32
          )
        ],
        v,
        r,
        s
      ]
    },
    () => {
      confirmationNumberRef.value = confirmationNumberRef.value + 1;
    }
  );
  if (!tx?.hash) return;

  saveHashToLocalStorage(
    `${t('appStatisticExchangeButton')} ${quantity} ${token.value.methodName.toUpperCase()}`,
    tx.hash
  );

  provider.on('block', async () => {
    confirmationNumberRef.value += 1;
  });

  store.updateVersion();

  await tx.wait(blockchain.minConfirmationsCount);
  provider.off('block');
  confirmationNumberRef.value = 0;
  modifyHistoryHashStatus(tx.hash, 'Done');
  showExchangePopup.value = false;
  store.updateVersion();
};
</script>

<style lang="scss" scoped>
.BurnCardcontainer {
  background-color: #041a27;
  min-height: 184px;

  &:hover {
    background-image: radial-gradient(circle closest-corner at 50% 100%, #3b7285, rgba(0, 0, 0, 0));
  }

  border: 1px solid #1e353e;
  border-radius: 12px;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.7));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 3px 10px;
  font-size: 24px;
  color: #90989d;
  line-height: 1;
  position: relative;
  cursor: pointer;
  max-height: 184px;

  .balance {
    font-size: 26px;
    color: white;
  }

  .rateBlock {
    text-align: center;
  }

  .imgContainer {
    width: 100%;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .rate {
    display: flex;
    padding-left: 10px;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    align-items: center;
    > img {
      margin-bottom: -5%;
    }
  }
}

.burn-card-container-ru {
  font-size: 19px;
}
</style>

<style lang="scss">
.seasonModal {
  .notEnoughTooltipContainer {
    border: 1px solid #1e353e !important;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.85) !important;
    font-size: 24px;
    color: white;
    font-family: Grenze, sans-serif;
    padding: 16px;
  }
}
</style>

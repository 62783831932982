<template>
  <div class="story-container">
    <div class="card story" :class="{ closed: isSidebarClosed }">
      <h1 class="heading-h4-size mg-bottom-12px">{{ $t('menuStory') }}</h1>
      <div class="inner-container _900px center _100---tablet scroll">
        <div class="w-layout-grid grid-1-column">
          <a
            data-w-id="6cb19750-7529-9840-277f-06fdd9228908"
            href="#"
            class="card-link-icon game-event w-inline-block"
            @click="showPopup = true"
          >
            <div class="contact-link-card-content">
              <div class="inner-container _48px mg-right-16px">
                <div class="mg-bottom-8px event-image">
                  <img
                    src="/img/pirate-avatar.jpg"
                    sizes="(max-width: 767px) 38px, (max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px"
                    alt=""
                    class="image-2"
                  />
                </div>
              </div>
              <div class="mg-right-8px">
                <h2 class="heading-h5-size mg-bottom-4px">
                  <span class="text-no-wrap">{{ $t('historyModalIntroVideo') }}</span>
                </h2>
                <div class="mg-bottom-8px">
                  <div class="event-type">{{ $t('historyModalVideo') }}</div>
                </div>
                <p class="mg-bottom-0">
                  <span>{{ $t('historyModalIntroVideoDescription') }}</span>
                </p>
              </div>
            </div>
            <div class="card-icon">
              <img
                class="line-rounded-icon link-icon-right card-link-right-icon"
                src="/public/img/icons/play_video.svg"
                loading="lazy"
              />
            </div>
          </a>
          <a data-w-id="490bd470-aa8e-a6c6-917b-16848a4586c9" href="#" class="card-link-icon game-event w-inline-block">
            <div class="contact-link-card-content">
              <div class="inner-container _48px mg-right-16px">
                <div class="mg-bottom-8px event-image">
                  <img
                    src="/img/pirate-avatar.jpg"
                    sizes="(max-width: 767px) 38px, (max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px"
                    alt=""
                    class="image-2"
                  />
                </div>
              </div>
              <div class="mg-right-8px">
                <h2 class="heading-h5-size mg-bottom-4px">
                  {{ $t('historyModalIntelligenceGathering') }} <span class="text-no-wrap" />
                </h2>
                <div class="mg-bottom-8px">
                  <div class="event-type">{{ $t('historyModalEventTypePassed') }}</div>
                </div>
                <p class="mg-bottom-0">
                  <span class="text-span">{{ $t('historyModalIntelligenceGatheringDescription') }}</span>
                </p>
              </div>
            </div>
          </a>
          <div
            data-w-id="0fecb4e9-8338-f6d1-e608-32c5dd66b2f6"
            class="card-link-icon game-event active w-inline-block"
            @click="runShipAnimation()"
          >
            <div class="contact-link-card-content">
              <div class="inner-container _48px mg-right-16px">
                <div class="mg-bottom-8px event-image">
                  <img
                    src="/img/pirate-avatar.jpg"
                    sizes="(max-width: 767px) 38px, (max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px"
                    alt=""
                    class="image-2"
                  />
                </div>
              </div>
              <div class="mg-right-8px">
                <h2 class="heading-h5-size mg-bottom-4px">{{ $t('historyModalAttackOnSpanishShip') }}</h2>
                <div class="mg-bottom-8px">
                  <div class="event-type active">{{ $t('historyModalEventTypeActive') }}</div>
                </div>
                <p class="mg-bottom-0">
                  <span>{{ $t('historyModalAttackOnSpanishShipDescription') }}</span>
                </p>
              </div>
            </div>
            <div class="card-icon">
              <img
                class="line-rounded-icon link-icon-right card-link-right-icon"
                src="/public/img/icons/attack_ship_arrow.svg"
                loading="lazy"
              />
            </div>
          </div>
          <a href="#" class="card-link-icon game-event locked w-inline-block">
            <div class="contact-link-card-content">
              <div class="inner-container _48px mg-right-16px">
                <div class="mg-bottom-8px event-image">
                  <img
                    src="/img/pirate-avatar.jpg"
                    sizes="(max-width: 767px) 38px, (max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px"
                    alt=""
                    class="image-2"
                  />
                </div>
              </div>
              <div class="mg-right-8px">
                <h2 class="heading-h5-size mg-bottom-4px">{{ $t('historyModalPearlsTransported') }}</h2>
                <div class="mg-bottom-8px">
                  <div class="event-type pending">{{ $t('historyModalEventTypeLocked') }}</div>
                </div>
                <p class="mg-bottom-0">
                  <span> {{ $t('historyModalPearlsTransportedDescription') }}</span>
                </p>
              </div>
            </div>
          </a>
          <a href="#" class="card-link-icon game-event locked w-inline-block">
            <div class="contact-link-card-content">
              <div class="inner-container _48px mg-right-16px">
                <div class="mg-bottom-8px event-image">
                  <img
                    src="/img/pirate-avatar.jpg"
                    sizes="(max-width: 767px) 38px, (max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px"
                    alt=""
                    class="image-2"
                  />
                </div>
              </div>
              <div class="mg-right-8px">
                <h2 class="heading-h5-size mg-bottom-4px">{{ $t('historyModalPearlDiversHired') }}</h2>
                <div class="mg-bottom-8px">
                  <div class="event-type pending">{{ $t('historyModalEventTypeLocked') }}</div>
                </div>
                <p class="mg-bottom-0">
                  <span>{{ $t('historyModalPearlDiversHiredDescription') }}</span>
                </p>
              </div>
            </div>
          </a>
          <a href="#" class="card-link-icon game-event locked w-inline-block">
            <div class="contact-link-card-content">
              <div class="inner-container _48px mg-right-16px">
                <div class="mg-bottom-8px event-image">
                  <img
                    src="/img/pirate-avatar.jpg"
                    sizes="(max-width: 767px) 38px, (max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px"
                    alt=""
                    class="image-2"
                  />
                </div>
              </div>
              <div class="mg-right-8px">
                <h2 class="heading-h5-size mg-bottom-4px">{{ $t('historyModalExpeditionStarts') }}</h2>
                <div class="mg-bottom-8px">
                  <div class="event-type pending">{{ $t('historyModalEventTypeLocked') }}</div>
                </div>
                <p class="mg-bottom-0">
                  <span>{{ $t('historyModalExpeditionStartsDescription') }}</span>
                </p>
              </div>
            </div>
          </a>
          <a href="#" class="card-link-icon game-event locked w-inline-block">
            <div class="contact-link-card-content">
              <div class="inner-container _48px mg-right-16px">
                <div class="mg-bottom-8px event-image">
                  <img
                    src="/img/pirate-avatar.jpg"
                    sizes="(max-width: 767px) 38px, (max-width: 1439px) 48px, (max-width: 1919px) 3vw, 48px"
                    alt=""
                    class="image-2"
                  />
                </div>
              </div>
              <div class="mg-right-8px">
                <h2 class="heading-h5-size mg-bottom-4px">{{ $t('historyModalGoldMintingRenewed') }}</h2>
                <div class="mg-bottom-8px">
                  <div class="event-type pending">{{ $t('historyModalEventTypeLocked') }}</div>
                </div>
                <p class="mg-bottom-0">
                  <span>{{ $t('historyModalGoldMintingRenewedDescription') }}</span>
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <a
      data-w-id="2275f037-e203-51ea-bcac-16dd7bb751fa"
      :class="{ closedBtn: isSidebarClosed }"
      href="#"
      class="sidebar-btn w-inline-block"
      @click="isSidebarClosed = !isSidebarClosed"
    >
      <img class="button-arrow" src="/public/img/icons/hide_videos.svg" loading="lazy" />
    </a>

    <el-dialog
      v-if="showPopup"
      v-model="showPopup"
      :fullscreen="false"
      :title="$t('historyModalIntroVideo')"
      :append-to-body="true"
      class="video-popup"
      @close="closePopup"
    >
      <div class="video-wrapper">
        <iframe
          class="video-iframe"
          src="https://www.youtube.com/embed/Mn1VKv8nh3s?si=dB-tHaf89WzC6bMH"
          :title="$t('historyModalYoutubePlayer')"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ElDialog } from 'element-plus';
import { useDevice } from '#imports';

const { isMobile } = useDevice();
const isSidebarClosed = ref(isMobile);
const showPopup = ref(false);

const emit = defineEmits(['runAnimation']);

function closePopup() {
  showPopup.value = false;
}

function runShipAnimation() {
  emit('runAnimation', 'Ship_battle');
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.card.story.closed {
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}

.mg-right-16px {
  margin-right: 16px;
}

.inner-container._48px {
  max-width: 48px;
}

@media screen and (max-width: 767px) {
  .inner-container._48px.mg-right-16px {
    max-width: 38px;
    min-width: 38px;
  }
}

@media screen and (max-width: 479px) {
  .inner-container._48px.mg-right-16px {
    width: 80%;
    max-width: 100%;
    min-width: auto;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }
}

h1 {
  color: #fff;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#ffde98, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 56px;
  font-family: Grenze, sans-serif;
  font-weight: 800;
  line-height: 1.222em;
}

h2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 40px;
  font-family: Grenze, sans-serif;
  font-weight: 800;
  line-height: 1.316em;
}

.heading-h4-size {
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  font-family: Grenze, sans-serif;
  line-height: 1.273em;
}

.heading-h5-size {
  color: #fff;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.333em;
}

.story-container {
  width: 510px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  overflow: visible;
  z-index: 5;
}

.card.story {
  z-index: 5;
  width: 510px;
  max-height: calc(80vh - 32px);
  opacity: 1;
  padding-top: 20px;
  padding-left: 32px;
  padding-right: 24px;
  position: absolute;
  right: 32px;
  overflow: auto;
  transition: 300ms ease all;
  scrollbar-width: thin;
  margin-top: 32px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 991px) {
    width: 300px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 16px;
    transform: translateY(-20%);
  }

  @media screen and (max-width: 767px) {
    max-height: 70vh;
    padding-left: 16px;
    padding-right: 8px;
    right: 0;
    transform: translateY(-25%);
  }

  @media screen and (max-width: 479px) {
    width: 220px;
  }
}

.sidebar-btn {
  z-index: 8;
  width: 64px;
  height: 64px;
  transform: scale3d(1, 1, 1);
  transform-style: preserve-3d;
  background-image: linear-gradient(to right, #3b7285, #17353f);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4);

  &:hover {
    color: #8cfd9f;
  }

  @media screen and (max-width: 767px) {
    right: 0;
  }

  @media screen and (max-width: 479px) {
    width: 50px;
    height: 50px;
  }
}

.card-link-right-icon {
  color: #fff;
  font-family:
    Line Rounded Icons,
    sans-serif;
  font-size: 24px;
  line-height: 1em;
}

.line-square-icon {
  font-family:
    Line Square Icons,
    sans-serif;
}

.button-arrow {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  transition: 300ms ease all;
  &:hover {
    transform: translate3d(-4px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  }
}

.closedBtn .button-arrow {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg);
  transform-style: preserve-3d;
  &:hover {
    transform: translate3d(-4px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg);
  }
}

.card-link-icon {
  transform-style: preserve-3d;
  background-color: #0a0a0a;
  border: 1px solid #2f2f2f;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  text-decoration: none;
  transition:
    background-color 0.3s,
    transform 0.3s,
    border-color 0.3s,
    color 0.3s;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: #161616;
    border-color: #2f2f2f;
    transform: scale3d(0.98, 0.98, 1.01);
  }
}

.card-link-icon.game-event {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(47, 47, 47, 0.4);
  padding-left: 20px;
  padding-right: 20px;
  transition:
    background-color 0.3s,
    transform 0.3s,
    border-color 0.3s;

  &:hover {
    color: rgba(255, 255, 255, 0.4);
    background-color: rgba(47, 47, 47, 0.8);
  }

  @media screen and (max-width: 991px) {
    padding: 12px 16px;
    font-size: 16px;
    line-height: 144%;
  }

  @media screen and (max-width: 479px) {
    text-align: left;
    flex-direction: row;
    padding-top: 10px;
    font-size: 12px;
  }
}

.heading-h5-size.mg-bottom-4px {
  @media screen and (max-width: 767px) {
    margin-right: 40px;
  }

  @media screen and (max-width: 479px) {
    margin-right: 0;
    font-size: 14px;
  }
}

.card-icon {
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  @media screen and (max-width: 767px) {
    top: 10px;
    right: 10px;
  }
}

.contact-link-card-content {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    margin-right: -19px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: row;
    margin-right: 0;
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
  }
}

.line-rounded-icon.link-icon-right.card-link-right-icon {
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
}

.event-type {
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  width: fit-content;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 150%;
  margin: 7px 0px;

  @media screen and (max-width: 479px) {
    width: 100%;
    margin-left: 10px;
  }
  font-family: Grenze, sans-serif;
}

.event-type.active {
  color: #fff;
  border-color: #7fdca4;
}

.card-link-icon.game-event.active {
  background-color: rgba(31, 51, 56, 0.4);
  border-color: #14282e;
}

.mg-bottom-4px {
  margin-bottom: 4px;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-1-column {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.image-2 {
  border-radius: 8px;
}

.video-wrapper {
  position: relative;
  padding-bottom: calc(56.25% * 0.75); /* 16:9 */
  height: 0;
  text-align: center;
  margin: auto;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

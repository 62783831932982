<template>
  <div class="modalInnerWrapper">
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.modalInnerWrapper {
  border-radius: 10px;
  border: 1px solid #1e353e;
  background: #0d242f;
  padding: 28px;
  width: 100%;
  overflow: auto;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.7));
  max-height: 40dvh;
  position: relative;
}
</style>

import { BigNumber } from 'bignumber.js';
import {
  SVGResource,
  Application,
  Assets,
  Container,
  Matrix,
  Sprite,
  Text,
  TextStyle,
  Texture,
  type ITextStyle
} from 'pixi.js';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import tokens from '~/public/img/tokens';
Assets.add({ alias: 'primary', src: '/img/gradientBackground/primary.png' });
Assets.add({ alias: 'hilighted', src: '/img/gradientBackground/hilighted.png' });
Assets.add({ alias: 'hilightedHover', src: '/img/gradientBackground/hilightedHover.png' });
Assets.add({ alias: 'season_cup', src: '/img/season_cup.png' });
Object.keys(tokens).forEach((token) => {
  Assets.add({ alias: token, src: tokens[token as keyof typeof tokens] });
});

export enum ButtonBG {
  primary = 'primary',
  hilighted = 'hilighted',
  hilightedHover = 'hilightedHover'
}

export type TPosition = 'center' | 'top';

const getIconPositionY = (iconPosition: TPosition, y: number, radius: number, imageHeight: number) => {
  switch (iconPosition) {
    case 'top':
      return y - (radius + 18);
    case 'center':
      return y - imageHeight / 2;
    default:
      return y;
  }
};

export default async (
  app: Application,
  xOffset: number,
  y: number,
  radius: number,
  iconAssetKey: Ref<string, string>,
  clickHandler?: () => void,
  bgVariant = ButtonBG.primary,
  label?: string,
  extraLabel?: Ref<number, number>,
  iconPosition = 'center' as TPosition
) => {
  await Assets.load([...Object.keys(tokens), 'primary', 'hilighted', 'hilightedHover', 'season_cup']);
  const { locale } = useNuxtApp().$i18n;

  const buttonContainer = new Container();
  const circle = new Graphics();

  const imageSize = computed(() => (Object.keys(tokens).includes(iconAssetKey.value) ? radius * 1.5 : radius * 2));

  const image = Assets.get<Texture>(iconAssetKey.value);

  const btnImage = new Sprite(image);
  btnImage.roundPixels = true;
  btnImage.zIndex = 3;

  watch(
    iconAssetKey,
    (newKey) => {
      btnImage.texture = Assets.get<Texture>(newKey);
      btnImage.width = imageSize.value;
      btnImage.height = imageSize.value;
      btnImage.position = {
        x: app.renderer.width - xOffset - btnImage.width / 2,
        y: getIconPositionY(iconPosition, y, radius, btnImage.height)
      };
    },
    { immediate: true }
  );

  // update btnImage position
  app.renderer.on('resize', () => {
    btnImage.position = {
      x: app.renderer.width - xOffset - btnImage.width / 2,
      y: getIconPositionY(iconPosition, y, radius, btnImage.height)
    };
  });

  let leaderBoardText: Text;
  let leaderBoardLabel: Sprite;

  const createLeaderBoardText = (text: string | number) => {
    const style: Partial<ITextStyle> | TextStyle = {
      fontSize: '18px',
      fontWeight: '700',
      fontFamily: 'Eczar, sans-serif',
      fill: 0x742a19,
      align: 'center'
    };

    const textSprite = new Text(formatLeaderBoardText(text), style);
    textSprite.zIndex = 7;
    textSprite.height = 20;

    watch(
      locale,
      (newLocale) => {
        if (newLocale === 'ru') {
          textSprite.style.fontFamily = 'PT Serif, sans-serif';
        } else {
          textSprite.style.fontFamily = 'Eczar, sans-serif';
        }
      },
      { immediate: true }
    );

    textSprite.position = {
      x: app.renderer.width - xOffset - textSprite.width / 2,
      y: btnImage.position.y + btnImage.height - textSprite.height - 2
    };

    return textSprite;
  };

  const createLeaderBoardLabel = () => {
    const svgRes = new SVGResource('/img/leaderboard_label.svg');

    //@ts-ignore
    const texture = Texture.from(svgRes);

    const leaderBoardLabel = new Sprite(texture);
    leaderBoardLabel.zIndex = 6;
    leaderBoardLabel.height = 20;
    leaderBoardLabel.width = 50;
    leaderBoardLabel.position.set(app.renderer.width - xOffset - radius / 2 - 3, y + 3);

    return leaderBoardLabel;
  };

  const createLeaderBoard = (text: string | number) => {
    leaderBoardText = createLeaderBoardText(text);
    leaderBoardLabel = createLeaderBoardLabel();

    buttonContainer.addChild(leaderBoardText);
    buttonContainer.addChild(leaderBoardLabel);

    app.renderer.on('resize', () => {
      leaderBoardText.position = {
        x: app.renderer.width - xOffset - leaderBoardText.width / 2,
        y: btnImage.position.y + btnImage.height - leaderBoardText.height - 2
      };

      leaderBoardLabel.position.set(app.renderer.width - xOffset - radius / 2 - 3, y + 3);
    });
  };

  const destroyLeaderBoard = () => {
    if (!leaderBoardLabel?.destroyed) {
      leaderBoardLabel?.destroy();
    }

    if (leaderBoardText) {
      leaderBoardText.text = '';
    }
  };

  if (extraLabel && !BigNumber(extraLabel.value).isZero()) {
    createLeaderBoard(extraLabel.value);
  }

  watch(extraLabel!, (newContent) => {
    if (!newContent || BigNumber(newContent).isZero()) {
      destroyLeaderBoard();
      return;
    }
    destroyLeaderBoard();
    createLeaderBoard(newContent);
  });

  if (label) {
    const style: Partial<ITextStyle> | TextStyle = {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'Grenze, sans-serif',
      fill: 0xffffff,
      align: 'center'
    };

    const textSprite = new Text(label, style);

    watch(
      locale,
      (newLocale) => {
        if (newLocale === 'ru') {
          textSprite.style.fontFamily = 'PT Serif, sans-serif';
        } else {
          textSprite.style.fontFamily = 'Eczar, sans-serif';
        }
      },
      { immediate: true }
    );

    textSprite.zIndex = 5;
    textSprite.position = {
      x: app.renderer.width - xOffset - textSprite.width / 2,
      y: y + radius - textSprite.height + 2
    };

    const labelWrapper = new Graphics();

    labelWrapper.lineStyle({ width: 1, color: 0x1e353e });
    labelWrapper.beginFill(0x000000);
    labelWrapper.alpha = 0.8;
    labelWrapper.zIndex = 4;
    labelWrapper.drawRoundedRect(
      textSprite.position.x - 10,
      textSprite.position.y,
      textSprite.width + 20,
      textSprite.height + 4,
      15
    );
    labelWrapper.endFill();

    app.renderer.on('resize', () => {
      textSprite.position = {
        x: app.renderer.width - xOffset - textSprite.width / 2,
        y: y + radius - textSprite.height + 2
      };

      labelWrapper.clear();
      labelWrapper.lineStyle({ width: 1, color: 0x1e353e });
      labelWrapper.beginFill(0x000000);
      labelWrapper.alpha = 0.8;
      labelWrapper.zIndex = 2;
      labelWrapper.drawRoundedRect(
        textSprite.position.x - 10,
        textSprite.position.y,
        textSprite.width + 20,
        textSprite.height + 4,
        15
      );
      labelWrapper.endFill();
    });

    buttonContainer.addChild(textSprite);
    buttonContainer.addChild(labelWrapper);
  }

  buttonContainer.addChild(circle);
  buttonContainer.addChild(btnImage);

  buttonContainer.zIndex = 60;
  buttonContainer.eventMode = 'dynamic';
  buttonContainer.cursor = 'pointer';
  buttonContainer.sortableChildren = true;

  if (clickHandler) buttonContainer.on('pointerdown', clickHandler!);

  circle.beginTextureFill({
    texture: Assets.get(bgVariant),
    matrix: new Matrix(1, 0, 0, 1, -Assets.get(bgVariant).width / 2, -Assets.get(bgVariant).height / 2)
  });

  circle.lineStyle({ width: 2, color: 0xffffff });
  circle.drawCircle(0, 0, radius);
  circle.position.set(app.renderer.width - xOffset, y);
  circle.endFill();

  circle.zIndex = 1;

  app.renderer.on('resize', () => {
    circle.clear();
    circle.beginTextureFill({
      texture: Assets.get(bgVariant),
      matrix: new Matrix(1, 0, 0, 1, -Assets.get(bgVariant).width / 2, -Assets.get(bgVariant).height / 2)
    });

    circle.lineStyle({ width: 3, color: 0xffffff });
    circle.drawCircle(0, 0, radius);
    circle.position.set(app.renderer.width - xOffset, y);
    circle.endFill();

    circle.zIndex = 1;
  });

  buttonContainer.on('mouseover', () => {
    if (bgVariant === ButtonBG.hilighted) {
      circle.geometry.graphicsData[0].fillStyle.texture = Assets.get(ButtonBG.hilightedHover);
    }
    circle.geometry.graphicsData[0].lineStyle.color = 0xbc944d;
    //@ts-ignore
    circle.geometry.invalidate();
  });

  buttonContainer.on('mouseout', () => {
    if (bgVariant === ButtonBG.hilighted) {
      circle.geometry.graphicsData[0].fillStyle.texture = Assets.get(ButtonBG.hilighted);
    }
    circle.geometry.graphicsData[0].lineStyle.color = 0xffffff;

    //@ts-ignore
    circle.geometry.invalidate();
  });

  return buttonContainer;
};

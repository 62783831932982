<template>
  <div>
    <div class="transaction-notification">
      <a :href="link" target="_blank" class="link-wrap">
        <div class="transaction-status" :class="'status-' + status">
          <span>{{ getStatusLocalised(status) }}</span> <span class="link-icon" />
        </div>
        <div class="transaction-desc">{{ rec.desc }}</div>
        <div class="transaction-date">{{ new Date(rec.date).toLocaleString('en-GB') }}</div>
      </a>
      <div class="transaction-close" @click="close" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { modifyHistoryHashStatus } from '~/utils';
import type { HashRecord } from '~/utils';
import { useMainStore } from '~/stores/mainStore';
import useEnvs from '~/composables/useEnvs';
import { useWeb3ModalProvider } from '@web3modal/ethers/vue';
import { BrowserProvider } from 'ethers';
import { useI18n } from '#imports';

const emit = defineEmits(['close']);
const store = useMainStore();
const { walletProvider } = useWeb3ModalProvider();
const { t } = useI18n();

const props = defineProps<{ rec: HashRecord }>();
const status = ref(props.rec.status);
const { blockchain } = useEnvs();

onMounted(async () => {
  if (props.rec.status === 'Processing' && walletProvider.value) {
    const provider = new BrowserProvider(walletProvider.value);

    const [lastBlock, tx, txReceipt] = await Promise.all([
      provider.getBlockNumber(),
      provider.getTransaction(props.rec.hash),
      provider.getTransactionReceipt(props.rec.hash)
    ]);

    const confirms = tx && tx.blockNumber ? lastBlock - tx.blockNumber : -1;

    // res.blockNumber - Block number where this transaction was in. null if pending
    // res2.status - Boolean: TRUE if the transaction was successful, FALSE if the EVM reverted the transaction.
    let status = 'Processing';
    if (txReceipt !== null && txReceipt.status) {
      if (confirms > 0) {
        if (confirms >= blockchain.minConfirmationsCount) {
          status = 'Done';
        }
      }
    } else {
      if (confirms > 0 && confirms >= blockchain.minConfirmationsCount) {
        status = 'Failed';
      }
    }

    if (status !== props.rec.status) {
      // update status in local storage
      modifyHistoryHashStatus(props.rec.hash, status);
      store.updateVersion();
    }
  }
});

function getStatusLocalised(status: string) {
  if (status == 'Processing') return t('notificationProcessing');
  else if (status == 'Approve') return t('notificationApprove');
  else if (status == 'Done') return t('notificationDone');
  else if (status == 'Error') return t('notificationError');
}

function close() {
  emit('close');
}

const link = computed(() => {
  return blockchain.blockScanUrl + '/tx/' + props.rec.hash;
});
</script>

<style scoped lang="scss">
.transaction-notification {
  display: flex;
  gap: 24px;
  margin: 4px 0;
  background: var(--dark-1);
  height: 44px;
  border-radius: 42px;
  border: 1px solid var(--dark-4);
  box-sizing: border-box;
  align-items: center;
  font-size: 20px;
}

.link-wrap {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
  width: 100%;
}

.transaction-date {
  width: 220px;
  overflow: hidden;
}

.transaction-desc {
  text-transform: capitalize;
  overflow: hidden;
  width: 100%;
  flex-shrink: 10;
}
.transaction-status {
  width: 170px;
  overflow: hidden;
  display: flex;
  padding: 2px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  color: var(--dark-1);
  margin-left: 4px;
  box-sizing: border-box;
}

.status-Done {
  background-color: var(--confirm-color);
}

.status-Processing {
  background-color: var(--processing-color);
}

.status-Failed {
  background-color: var(--processing-color);
}

.transaction-close {
  margin-left: auto;
  cursor: pointer;
  margin-right: 14px;
  background-image: url('~/public/img/icons/bone_close.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  transition: 200ms ease opacity;
  min-width: 20px;

  &:hover {
    opacity: 0.6;
  }
}

.link-icon {
  width: 20px;
  height: 20px;
  display: inline-flex;
  background: url('~/public/img/icons/link-dark.png') center center no-repeat;
  background-size: contain;
  margin-left: 8px;
}

@media screen and (max-width: 767px) {
  .transaction-notification {
    font-size: 14px;
  }

  .transaction-desc {
    text-align: left;
    line-height: 18px;
  }

  .transaction-date {
    width: auto;
    line-height: 18px;
  }

  .transaction-status {
    width: 140px;
  }

  .link-wrap {
    gap: 16px;
  }
}

@media screen and (max-width: 479px) {
  .transaction-notification {
    font-size: 12px;
  }

  .transaction-status {
    width: 100px;
  }

  .link-wrap {
    gap: 12px;
  }
}
</style>

<template>
  <div ref="el" class="leaderboardItem" :class="{ ownPositionBG: own }">
    <p class="address">
      <span style="width: 45px">{{ position }}</span>
      <span :class="{ ownPositionRow: own }"
        >{{ $t('seasonPlayer') }}
        {{ '-' }}
        {{ formatAddress(address || '') }}</span
      >
    </p>
    <p class="score">
      <img width="24" :src="tokens.seasonPoints" alt="" />
      <span>{{ score }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import tokens from '~/public/img/tokens';
const el = ref<HTMLElement | null>(null);
const currentScrollTop = ref(0);
const currentScrollDirection = ref('');

const props = defineProps<{ address: string; score: number; position: number; own: boolean }>();
const emit = defineEmits<{
  changeShowStickyBgTop: [isShowStickyBgTop: boolean];
  changeShowStickyBgBottom: [isShowStickyBgBottom: boolean];
}>();
defineExpose({ el });

onMounted(() => {
  const actualElement = document.querySelector('.ownPosition')?.nextElementSibling;
  const parentScrollContainer = actualElement?.parentNode?.parentNode as HTMLElement;

  if (actualElement && props.own) {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (parentScrollContainer.scrollTop === 0) {
          currentScrollDirection.value = '';
        } else if (currentScrollTop.value > parentScrollContainer.scrollTop) {
          currentScrollDirection.value = 'down';
        } else {
          currentScrollDirection.value = 'up';
        }
        currentScrollTop.value = parentScrollContainer.scrollTop;

        if (entries[0].intersectionRatio < 1 && currentScrollDirection.value === 'up') {
          emit('changeShowStickyBgTop', true);
        } else {
          emit('changeShowStickyBgTop', false);
        }

        if (
          entries[0].rootBounds &&
          ((entries[0].intersectionRect.top === 0 &&
            !entries[0].isIntersecting &&
            currentScrollDirection.value === '' &&
            entries[0].intersectionRatio === 0) ||
            (entries[0].intersectionRatio < 1 && currentScrollDirection.value === 'down'))
        ) {
          emit('changeShowStickyBgBottom', true);
        } else {
          emit('changeShowStickyBgBottom', false);
        }
      },
      {
        threshold: [0.25, 0.75, 1],
        root: el.value?.parentNode?.parentNode as HTMLElement,
        rootMargin: '0px 0px -20px 0px'
      }
    );

    intersectionObserver.observe(actualElement as HTMLElement);
  }
});
</script>

<style lang="scss" scoped>
.leaderboardItem {
  height: 40px;
  border: 1px solid #3b7285;
  border-radius: 12px;
  padding: 0 24px;
  margin: 0 15px;
  background: linear-gradient(to bottom, #17343e 0%, #010303 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.ownPositionBG {
    background: linear-gradient(to bottom, #0083ad 0%, #00354f 100%);
  }

  .score,
  .address {
    display: flex;
    align-items: center;
  }

  .address {
    span {
      color: #90989d;
      font-size: 16px;
      font-family: Eczar, sans-serif;

      &.ownPositionRow {
        color: white;
      }

      &:first-child {
        color: #eea92e;
        font-size: 16px;
      }
    }
  }

  .score {
    span {
      color: #eea92e;
      font-size: 20px;
    }
  }
}

.own-background {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 60px;
  background: #040e13;
  z-index: -1;
}
</style>
